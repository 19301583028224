<template>
  <router-view />
</template>
<script>
export default {
  mounted() {
    const resize = function() {
      const clientWidth = window.innerWidth;
      const fontSize = (12 * clientWidth) / 375;
      document.documentElement.style.fontSize = `${fontSize}px`;
    }; 
    window.onresize = resize;
    resize();
  }, 

  unmounted() {
    window.onresize = null;
  },
};
</script>
