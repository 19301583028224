import axios, { AxiosRequestConfig } from "axios";
import { Toast , Notify } from "vant";
import router from "../router";
axios.defaults.timeout = 10000;

axios.interceptors.response.use(
    (response) => response,
    (error) => {}
);

/**
 *
 * @param {*} url
 * @param {*} data
 * @param {string} method
 */
function ajax(url, data, method) {
    const promise = new Promise((resolve, reject) => {
        let params = "";
        if (!data) {
            data = {};
        }
        data.sid=2;
        for (const p in data) {
            if (data.hasOwnProperty(p) && data[p] !== undefined) {
                params += `${encodeURIComponent(p)}=${encodeURIComponent(data[p])}&`;
            }
        }
        params = params.substr(0, params.length - 1);
        const options = {
            method,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            withCredentials: true,
        };
        switch (method.toUpperCase()) {
            case "GET":
            case "DELETE":
                {
                    url = url + "?" + params;
                    break;
                }
            case "POST":
            case "PUT":
                {
                    options.data = params;
                    break;
                }
            default:
                break;
        }
        axios(url, options)
            .then((res) => {
                if (res.status >= 200 && res.status < 300) {
                    if (res.data.code !== 0) {
                        // if(document.body.clientWidth>1200){
                        //     Notify({ type: 'danger', message: res.data.message });
                        // }else{
                            Toast.fail(res.data.message);
                        //}
                        // router.push({
                        //     path: "/error",
                        //     query: { error: res.data.message },
                        // });
                    }
                    // 请求成功
                    resolve(res.data);
                } else {
                    router.push({
                        path: "/error",
                        query: { error: res },
                    });
                    reject(res);
                }
            })
            .catch((msg) => {
                reject("网络开了点小差，请检查网络连接后重试");
            });
    });
    promise.catch((msg) => {
        if (msg) {
            // if(document.body.clientWidth>1200){
            //     Notify({ type: 'danger', message: msg });
            // }else{
                Toast.fail(msg);
            //}
        }
    });
    return promise;
}

export function get(url, data) {
    return ajax(url, data, "GET");
}

export function post(url, data) {
    return ajax(url, data, "POST");
}

export function del(url, data) {
    return ajax(url, data, "DELETE");
}

export function put(url, data) {
    return ajax(url, data, "PUT");
}

// https://org.quanmai.cloud/testapi/report/get_third_report_info?userId=2973&id=3536&sid=1
// https://org.quanmai.cloud/testapi/report/get_third_report_info?id=3536&user_id=2973&sid=1