import { getApi, postApi } from "./proxy";

// export function uploadPhoto(type, photo) {
//     return postApi("/report/img_upload", { type, photo });
// }
//新版流程上传图片
export function uploadPhoto(type, photo) {
    return postApi("/report/new_img_upload", { type, photo });
}

// export function getQuestions(uid) {
//     return getApi("/report/get_question", { user_id: uid });
// }
//新版流程获取题目
export function getQuestions(uid) {
    return getApi("/report/get_third_question", { user_id: uid });
}

//mac码换机器码
export function getMacCode(data) {
    return getApi("/equipment/get_by_mac", data);
}

//激活码绑定
export function postBindCode(data) {
    return postApi("equipment/bind_code", data);
}

export function submit(
    user_id,
    face_id,
    tongue_id,
    answer,
    sex,
    birthday,
    height,
    weight,
    user_phone,
    order_code,
    unique_token
) {
    return postApi("/report/submit_third", {
        user_id,
        face_id,
        tongue_id,
        answer,
        sex,
        birthday,
        height,
        weight,
        user_phone,
        order_code,
        unique_token,
        check_type: 2,
        user_name:'dg'
    });
}

export function getUserInfo() {
    return getApi("/report/get_third_question");
}

export function getReportInfo(uid, id) {
    return getApi("/report/get_report_by_id", { id, user_id: uid });
}

export function getHistory(user_id) {
    return getApi("/report/get_score_by_time", { user_id, method: 2 });
}

export function setTarget(report_id, user_id, target) {
    return postApi("/report/set_target", {
        id: report_id,
        target,
        user_id,
    });
}

export function getReportList(user_id, page) {
    return getApi("/report/get_report_list", { user_id, page, page_size: 4 });
}

export function getUserByCode(code) {
    return getApi("/user/get_user_by_code", { code });
}
//查看评估次数是否达到上限
export function checkAgency(code) {
    return getApi("/index/check_agency");
}