import { post, get , put } from './ajax';

export function postApi(url, data) {
    return post("/api" + url, data);
}

export function getApi(url, data) {
    return get("/api" + url, data);
}

export function getQuanmaiApi(url, data) {
    return get("/testapi" + url, data);
}

export function postQuanmaiApi(url, data) {
    return post("/quanmaiapi" + url, data);
}

export function putUserApi(url, data) {
    return put("/userapi" + url, data);
}

export function getUserApi(url, data) {
    return get("/userapi" + url, data);
}

export function getShopApi(url, data) {
    return get("/shopapi" + url, data);
}

export function postUserApi(url, data) {
    return post("/userapi" + url, data);
}