import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./styles/global.scss";
import { Uploader, Toast, Dialog , Notify , Tag, Swipe, SwipeItem, List, Loading, NavBar, Popup, RadioGroup, Radio, DatetimePicker } from "vant";
import { Plugin } from "vue-fragment";
import "vant/lib/uploader/style";
import "vant/lib/toast/style";
import "vant/lib/dialog/style";
import "vant/lib/notify/style";
import "vant/lib/tag/style";
import "vant/lib/swipe/style";
import "vant/lib/loading/style";
import "vant/lib/nav-bar/style";
import "vant/lib/list/style";
import "vant/lib/popup/style";
import "vant/lib/radio/style";
import "vant/lib/radio-group/style";
import "vant/lib/datetime-picker/style";
createApp(App)
    .use(router)
    .use(Uploader)
    .use(Toast)
    .use(Dialog)
    .use(Notify)
    .use(Tag)
    .use(Swipe)
    .use(SwipeItem)
    .use(List)
    .use(Plugin)
    .use(Loading)
    .use(NavBar)
    .use(Popup)
    .use(RadioGroup)
    .use(Radio)
    .use(DatetimePicker)
    .mount("#app");